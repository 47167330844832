import React, {useState} from 'react';
import Layout from '../components/layout'
import SEO from "../components/seo"
import Img from "gatsby-image"
import {Link, graphql } from "gatsby"
import { Styled, Flex, Box, Grid, Badge } from 'theme-ui'

const activeStyle = {
  background:"#CFB53B",
  color:"white"
}
function CategoryPost({data, location }){
    const poetry = data.allWordpressWpPoetry
    const essay   = data.allWordpressPost
    const category = data.allWordpressCategory
    // const currentTopic = location.href.substring(location.href.lastIndexOf('/') + 1)
    const [category_name,setCategory] = useState("")

    return(
        <Layout>
        <ul style={{display:"flex", flexWrap :"wrap", marginLeft:"0"}}>
    {data.allWordpressCategory.edges.map(({ node }) => (
        <Styled.a as={Link} to={'/category/' + node.name} activeStyle={activeStyle}>
          <li style={{paddingRight: "1.45rem", listStyle:"none"}}>{node.name.charAt(0).toUpperCase() + node.name.slice(1)}
          </li>
          </Styled.a>
        ))}
          </ul>
          <h1>{category_name}</h1>
          <Grid  gap={4}
      columns={[ 1, null, 3 ]} >
        {poetry.edges.map(({ node }) => (
        <div>
        <Box key={'/poetry/' + node.slug}>
        <Styled.a as={Link}  to={'/poetry/' + node.slug}>
        <h3 dangerouslySetInnerHTML={{__html: node.title}} />
        <Badge>Poetry:{node.categories[0].name.charAt(0).toUpperCase() + node.categories[0].name.slice(1)}</Badge>
        <Img key={node.featured_media.localFile.childImageSharp.resolutions.src} fluid={node.featured_media.localFile.childImageSharp.fluid} 
        style ={{ padding:2}}/>
        </Styled.a>
        </Box>
        </div>
        ))} 

        {essay.edges.map(({ node }) => (
        <div>
        <Box key={'/essays/' + node.slug}>
        <Styled.a as={Link}  to={'/essays/' + node.slug}>
        <h3 dangerouslySetInnerHTML={{__html: node.title}} />
        <Badge>Essay:{node.categories[0].name.charAt(0).toUpperCase() + node.categories[0].name.slice(1)}</Badge>
        <Img key={node.featured_media.localFile.childImageSharp.resolutions.src} fluid={node.featured_media.localFile.childImageSharp.fluid} 
        style ={{ padding:2}}/>
        </Styled.a>
        </Box>
        </div>
        ))} 
    </Grid>
      
    </Layout>
    )
}

export default CategoryPost

export const query = graphql`
query($slug: String!) {
    allWordpressPost (filter: {categories: {elemMatch: {slug: { eq: $slug }}}}) {
      edges {
        node {
        title
        slug
        content
        excerpt
        date(formatString: "YYYY-MM-DD")
        categories {
            id
            name
          }
        featured_media {
            localFile {
                childImageSharp {
                    fluid(maxWidth:300, maxHeight:300){
                    ...GatsbyImageSharpFluid
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                  resolutions(width:350, height: 500) {
                    ...GatsbyImageSharpResolutions_withWebp_tracedSVG
                  }
                }
            }
          }
      }
    }
  }
  allWordpressWpPoetry (filter: {categories: {elemMatch: {slug: { eq: $slug }}}}) {
    edges {
      node {
      title
      slug
      content
      excerpt
      categories {
        id
        name
      }
      date(formatString: "MMMM DD, YYYY")
      featured_media {
        localFile {
            childImageSharp {
              fluid(maxWidth:300, maxHeight:300){
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
              resolutions(width:350, height: 500) {
                ...GatsbyImageSharpResolutions_withWebp_tracedSVG
              }
            }
        }
      }
    }
  }
}
allWordpressCategory {
    edges {
      node {
        name
        slug
        wordpress_id
      }
    }
  }
}`
